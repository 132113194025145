/**
 * History-slider displays object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if history slider present
 */

const historySlider = (() => {
  function init() {
    $('.history-slider').slick({
      rows: 0,
      mobileFirst: true,
      arrows: false,
      responsive: [
        {
          breakpoint: 699,
          settings: {
            arrows: true,
          },
        },
      ],
    });
  }

  return {
    init,
  };
})();

export default historySlider;
