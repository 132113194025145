/**
 * Carousel display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if instagram is present
 */
const instagram = (() => {
  // Render carousel

  function renderInstagram() {
    const token =
      'IGQVJVNEhNMkgtT0hBT3BHaDNwMjhNdUZAvYjBvZAnRlMlRoQ1dvM2lhanE2TlVPVFA1UlNfbkhoZA3JUQ2dfQnptZA1ZARYTRTbWF3bzFoclYtd3RWdndla21KU3k0Nnd5RzY1R2tIUUJR';
    let itemCount = 0;
    if ($('#instafeed-home').length) {
      itemCount = 7;
    }

    if ($('#instafeed').length) {
      itemCount = 3;
    }

    const count = itemCount;
    const ids = [];

    $.ajax({
      method: 'GET',
      url: `https://graph.instagram.com/me/media?fields=id,caption&access_token=${token}`,
    }).done(PostIds => {
      for (let i = 0; i < count; i++) {
        ids.push(PostIds.data[i].id);
      }
      const runRequests = index => {
        if (ids.length === index) {
          return;
        }
        const id = ids[index];
        $.ajax({
          url: `https://graph.instagram.com/${id}?fields=caption,media_type,media_url,permalink,thumbnail_url&access_token=${token}`,
          success: data => {
            let mediaUrl = '';
            if (data.media_type === 'VIDEO') {
              mediaUrl = data.thumbnail_url;
            } else {
              mediaUrl = data.media_url;
            }
            /**
              Edit the html of each column/post here
              available variables:
                mediaUrl: the URL of the image (thumbnail image for video post types)
                data.permalink: Link to the post on insta
                data.caption: Post caption
                data.media_type: Type of Media
                  returns 'IMAGE' or 'VIDEO'
            */
            const html = `
              <div class="instagram__tile column__col">
                <a href="${data.permalink}" target="_blank" class="instagram__link">
                  <span class="instagram__caption">${data.caption}</span>
                  <img src="${mediaUrl}" alt="image from instagram"/>
                </a>
              </div>
              `;
            $('.instagram__feed')
              .append(html)
              .addClass('js-fade-in');
          },
          complete() {
            runRequests(++index);
          },
        });
      };
      runRequests(0);
    });
  }

  const trimCaption = () => {
    $('.instagram__caption:not(.trim)').each(function() {
      $(this)
        .addClass('trim')
        .html(
          `${$(this)
            .html()
            .substring(0, 160)} ...`,
        );
    });
  };

  const instagramHome = {
    rows: 0,
    mobileFirst: true,
    centerMode: true,
    centerPadding: '30px',
    arrows: false,
    responsive: [
      {
        breakpoint: 699,
        settings: {
          slidesToShow: 2,
          arrows: true,
          centerPadding: '85px',
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2,
          arrows: true,
          centerPadding: '52px',
        },
      },
    ],
  };

  const instagramPattern = {
    arrows: false,
    infinite: false,
    slidesToScroll: 1,
    mobileFirst: true,
    draggable: false,
    variableWidth: true,
    swipe: true,
    responsive: [
      {
        breakpoint: 699,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1025,
        settings: 'unslick',
      },
    ],
  };

  function instaSlickInit() {
    $('#instafeed-home:not(.slick-initialized)').slick(instagramHome);
    $('#instafeed:not(.slick-initialized)').slick(instagramPattern);
  }

  const init = () => {
    if (!$('.instafeed').length) {
      return;
    }

    renderInstagram();
    $(window).on('load resize', () => {
      setTimeout(() => {
        instaSlickInit();
        trimCaption();
      }, 3000);
    });
  };

  return {
    init,
  };
})();

export default instagram;
