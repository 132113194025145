const news = (() => {
  const init = () => {
    if (!$('.news__filters').length) {
      return;
    }

    function expandFilters() {
      $(this).toggleClass('expanded');
      $('.news__filters').slideToggle();

      if ($(this).hasClass('expanded')) {
        $(this).attr('aria-expanded', 'true');
      } else {
        $(this).attr('aria-expanded', 'false');
      }
    }

    $('.news__expand-filters').attr('aria-expanded', 'false');
    $('.news__expand-filters').click(expandFilters);
  };

  return {
    init,
  };
})();

export default news;
