/**
 * Inline video play functionality using YouTube API
 * @param {*} $
 * @returns {init} Determine and run if inline video exists
 */

/* global YT */

const tag = document.createElement('script');

tag.src = 'https://www.youtube.com/iframe_api';
const firstScriptTag = document.getElementsByTagName('script')[0];
firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

const inlineVideo = (() => {
  function vidInline() {
    // e.preventDefault();

    // Attach YouTube API
    const videoElem = $(this).parents('.vid-inline__container');
    const videoCloseBtn = $(this)
      .parents('.vid-inline')
      .children('.vid-inline__close');
    const video = videoElem.find('iframe')[0];
    let player;

    function onYouTubeIframeAPIReady() {
      setTimeout(() => {
        player = new YT.Player(video, {
          events: {
            // eslint-disable-next-line no-use-before-define
            onReady: onPlayerReady,
            // eslint-disable-next-line no-use-before-define
            onStateChange: onPlayerStateChange,
          },
        });
      }, 100);
    }

    onYouTubeIframeAPIReady();

    function onPlayerReady() {
      if (player.playVideo) {
        player.playVideo();
        videoCloseBtn.removeAttr('tabindex');
        videoCloseBtn.focus();
        $(video).attr('tabindex', '0');
      }
    }

    function onPlayerStateChange(event) {
      if (event.data === YT.PlayerState.UNSTARTED) {
        videoElem.find('.vid-inline__cover').addClass('js-loading');
      }

      if (event.data === YT.PlayerState.PLAYING) {
        videoElem.find('.vid-inline__cover').addClass('js-hide');
        videoElem.find('.vid-inline__embed').addClass('js-show');
        videoCloseBtn.addClass('js-show');
        videoElem.find('iframe').removeAttr('tabindex');
      }
    }
    $('.vid-inline__cover').on('click keypress', onPlayerReady);
    videoCloseBtn.on('click', () => {
      if (player.stopVideo) {
        player.stopVideo();
        $('.vid-inline__cover').focus();
        $(video).attr('tabindex', '-1');
        videoCloseBtn.attr('tabindex', '-1');

        setTimeout(() => {
          videoElem
            .find('.vid-inline__cover')
            .removeClass('js-hide js-loading');
          videoElem.find('.vid-inline__embed').removeClass('js-show');
          videoCloseBtn.removeClass('js-show');
        }, 100);
      }
    });
  }

  const init = () => {
    if (!$('.vid-inline').length) {
      return;
    }

    $('.vid-inline__cover').on('click keypress', vidInline);
  };

  return {
    init,
  };
})();

export default inlineVideo;
