/**
 * Breadcrumbs-nav shows up hidden dropdowns
 * @param {*} $
 * @returns {init} Determine and run if breadcrumbs-nav present
 */

const stickyNav = (() => {
  function init() {
    const $window = $(window);
    const $breadcrumbs = $('.breadcrumbs-wrapper');
    if (!$breadcrumbs.length) {
      return;
    }
    let breadcrumbsPosition = $breadcrumbs.offset().top;
    const stickyClass = 'scrolled';
    $window.on('scroll resize load orientationchange', () => {
      breadcrumbsPosition = $breadcrumbs.offset().top;
      if ($window.scrollTop() >= breadcrumbsPosition) {
        $breadcrumbs.addClass(stickyClass);
      } else {
        $breadcrumbs.removeClass(stickyClass);
      }
    });
  }

  return {
    init,
  };
})();

export default stickyNav;
