/**
 * Accordion-simple displays main navigation
 * @param {*} $
 * @returns {init} Determine and run if main-nav present
 */

const accordionNav = (() => {
  function init() {
    const $menuItemOpener = $('.nav-accordion__opener');
    const $slides = $('.nav-accordion .slide');
    const ACTIVE_CLASSNAME = 'active-item';

    $menuItemOpener.click(e => {
      const $this = $(e.currentTarget);
      const $currentSlide = $this.siblings('.slide');

      if (!$this.hasClass(ACTIVE_CLASSNAME)) {
        $slides.stop().slideUp();
        $menuItemOpener.removeClass(ACTIVE_CLASSNAME);
        $this.addClass(ACTIVE_CLASSNAME);
        $currentSlide.stop().slideDown();
        $menuItemOpener.attr('aria-expanded', 'false');
        $slides.attr('aria-hidden', 'true');
        $this.attr('aria-expanded', 'true');
        $currentSlide.attr('aria-hidden', 'false');
      } else {
        $menuItemOpener.removeClass(ACTIVE_CLASSNAME);
        $slides.stop().slideUp();
        $menuItemOpener.attr('aria-expanded', 'false');
        $slides.attr('aria-hidden', 'true');
      }
    });
  }

  return {
    init,
  };
})();

export default accordionNav;
