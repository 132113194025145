/**
 * Side-nav displays side nav on mobile divices
 * @param {*} $
 * @returns {init} Determine and run if side-nav present
 */

const sideNav = (() => {
  function init() {
    const $opener = $('.side-nav button');
    const $navBlock = $('.side-nav');
    const $content = $('.side-nav ul');
    const scrollTo = $content.offset().top + $content.height();

    $opener.click(() => {
      $content.slideToggle();
      $navBlock.toggleClass('opened');
      $('.nav-block').animate(
        {
          scrollTop: scrollTo,
        },
        300,
      );
      if ($navBlock.hasClass('opened')) {
        $opener.attr('aria-expanded', 'true');
        $content.attr('aria-hidden', 'false');
      } else {
        $opener.attr('aria-expanded', 'false');
        $content.attr('aria-hidden', 'true');
      }
    });

    $(window).resize(() => {
      if ($(window).width() > 700) {
        $content.removeAttr('aria-hidden');
      } else {
        $content.attr('aria-hidden', 'true');
      }
    });

    if ($(window).width() > 700) {
      $content.removeAttr('aria-hidden');
    }
  }

  return {
    init,
  };
})();

export default sideNav;
