import '@babel/polyfill';
import 'slick-carousel';
import 'object-fit-images';

import accordion from './object/accordion';
import inlineVideo from './object/video';
import instagram from './object/instagram';
import twitterLoader from './object/twitter';
import catalogProgram from './object/catalog-program';
import carousel from './object/carousel';
import programFinder from './object/program-finder';
import calendar from './object/calendar';
import catalog from './object/catalog';
import scrollClass from './object/scroll-nav';
import mainNav from './object/main-nav';
import accordionNav from './object/accordion-simple';
import sideNav from './object/side-nav';
import searchPopup from './object/search-popup';
import scrollTop from './object/scroll-top';
import scrollLock from './object/scroll-lock';
import fabBlock from './object/fab';
import breadcrumbsNav from './object/breadcrumbs';
import stickyNav from './object/sticky-nav';
import heroVideo from './object/hero-video';
import historySlider from './object/history-slider';
import alerts from './object/alerts';
import campusSlider from './object/campus-slider';
import differenceVideo from './object/difference-video';
import facultyTable from './object/facultyTable';
import news from './object/news';
import wowAnimate from './object/wow';
import tableScroll from './object/scroll-tables';

window.$ = require('jquery');
window.jQuery = require('jquery');
const objectFitImages = require('object-fit-images');

$(() => {
  instagram.init();
  accordion.init();
  inlineVideo.init();
  twitterLoader.init();
  calendar.init();
  catalogProgram.init();
  carousel.init();
  programFinder.init();
  catalog.init();
  objectFitImages();
  scrollClass.init();
  mainNav.init();
  accordionNav.init();
  sideNav.init();
  searchPopup.init();
  scrollTop.init();
  scrollLock.init();
  fabBlock.init();
  breadcrumbsNav.init();
  stickyNav.init();
  heroVideo.init();
  historySlider.init();
  campusSlider.init();
  differenceVideo.init();
  facultyTable.init();
  news.init();
  wowAnimate.init();
  tableScroll.init();

  // Run after instagram and others have loaded
  $(window).on('load', () => {
    alerts.init();
  });
});
