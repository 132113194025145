/**
 * Fab shows fab-block content by clicking on fab button
 * @param {*} $
 * @returns {init} Determine and run if fab present
 */

const fabBlock = (() => {
  function init() {
    const $fabContainer = $('.fab-container');
    const $fabBlock = $('.fab-container__block');
    const $fabButton = $('.fab-container__block button');
    const $fabContent = $('.fab-container__block ul');
    const $footer = $('.footer');
    const $window = $(window);
    const FIXED_BTN_CLASS = 'is-bottom';
    let footerBtnPosition;
    let footerPadTop;
    let currentScroll;

    $window.scroll(() => {
      footerPadTop = $('.footer .contact-block').css('padding-top');
      footerBtnPosition =
        +footerPadTop.slice(0, footerPadTop.length - 2) + $footer.offset().top;
      currentScroll = $window.scrollTop() + $window.height() + 37;

      if (currentScroll > footerBtnPosition) {
        $fabContainer.addClass(FIXED_BTN_CLASS);
      } else {
        $fabContainer.removeClass(FIXED_BTN_CLASS);
      }
    });

    $fabButton.click(() => {
      $fabBlock.toggleClass('expanded');
      $fabContent.fadeToggle();
      if ($fabBlock.hasClass('expanded')) {
        $fabButton.attr('aria-expanded', 'true');
        $fabContent.attr('aria-hidden', 'false');
      } else {
        $fabButton.attr('aria-expanded', 'false');
        $fabContent.attr('aria-hidden', 'true');
      }
    });
  }

  return {
    init,
  };
})();

export default fabBlock;
