/**
 * Difference video displays videos interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if difference video present
 */

const differenceVideo = (() => {
  function init() {
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    const $videoHolder = $('.difference-block__block');
    const $video = $videoHolder.find('iframe');

    function onPlayerReady(event) {
      const $parentHolder = $(event.target.f).closest(
        '.difference-block__block',
      );
      const $playBtn = $parentHolder.find('.difference-block__play');

      $playBtn.click(() => {
        if (!$parentHolder.hasClass('is-playing')) {
          $parentHolder.addClass('js-loading');
          event.target.playVideo();
        } else {
          event.target.pauseVideo();
          $parentHolder.removeClass('is-playing');
        }
      });
    }

    function onPlayerStateChange(event) {
      const $parentHolder = $(event.target.f).closest(
        '.difference-block__block',
      );
      const playerState = event.target.getPlayerState();

      if (playerState === 1) {
        $parentHolder.addClass('is-playing').removeClass('js-loading');
      } else if (playerState === 2 || playerState === -1) {
        $parentHolder.removeClass('is-playing');
      }
    }

    window.onYouTubeIframeAPIReady = () => {
      $video.each((i, el) => {
        const player = new YT.Player(el, {
          events: {
            onReady: onPlayerReady,
            onStateChange: onPlayerStateChange,
          },
        });
      });
    };
  }

  return {
    init,
  };
})();

export default differenceVideo;
