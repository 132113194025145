/**
 * Wow shows animations on scroll
 * @param {*} $
 * @returns {init} Determine and run if wow present
 */

const WOW = require('wow.js');

const wowAnimate = (() => {
  function init() {
    const $window = $(window);

    const wow = new WOW({
      offset: 100,
      mobile: false,
    });
    wow.init();
  }

  return {
    init,
  };
})();

export default wowAnimate;
