/**
 * Search-popup displays search-block
 * @param {*} $
 * @returns {init} Determine and run if search-popup present
 */

const searchPopup = (() => {
  function init() {
    const $searchOpener = $('.search-opener');
    const $searchClose = $('.search-close');
    const $searchBlock = $('.search-popup');
    const $submitBtn = $searchBlock.find('button[type="submit"]');

    let timeout = null;

    $searchOpener.click(() => {
      $searchOpener.attr('aria-expanded', 'true');
      $searchBlock.addClass('search-open').attr('aria-hidden', 'false');

      clearTimeout(timeout);

      timeout = setTimeout(() => {
        $searchClose.focus();
      }, 500);
    });

    $submitBtn.on('keydown', e => {
      if (e.keyCode === 9 && e.shiftKey === false) {
        e.preventDefault();
        $searchClose.focus();
      }
    });

    $searchClose.on('keydown', e => {
      if (e.keyCode === 9 && e.shiftKey === true) {
        e.preventDefault();
        $submitBtn.focus();
      }
    });

    $searchClose.click(() => {
      $searchOpener.attr('aria-expanded', 'false');
      $searchBlock.removeClass('search-open').attr('aria-hidden', 'true');
    });
  }

  return {
    init,
  };
})();

export default searchPopup;
