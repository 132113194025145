/**
 * Scroll-top scrolls page to the top on click
 * @param {*} $
 * @returns {init} Determine and run if scroll-top present
 */

const scrollTop = (() => {
  function init() {
    $('.back-to-top').click(() => {
      $('html,body').animate({ scrollTop: 0 }, '1000');
      $('.header .logo a').focus();
    });
  }

  return {
    init,
  };
})();

export default scrollTop;
