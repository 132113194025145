/**
 * Scroll-nav adds class for header when the page is scrolled
 * @param {*} $
 * @returns {init} Determine and run if scroll-nav present
 */

const scrollClass = (() => {
  function init() {
    const $header = $('.header');
    const scrollDistance = 140;
    const $controls = $('.nav-controls .search-opener,.nav-controls .nav-opener');

    $(window).scroll(() => {
      const scroll = $(window).scrollTop();

      if ($(window).width() > 699) {
        if (scroll >= scrollDistance) {
          $header.addClass('scrolled');
          $controls.attr('tabindex','-1');
        } else {
          $header.removeClass('scrolled');
          $controls.removeAttr('tabindex');
        }
      }
    });
  }

  return {
    init,
  };
})();

export default scrollClass;
