/**
 * History-slider displays object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if history slider present
 */

const campusSlider = (() => {
  function init() {
    $('.campus-slider').slick({
      rows: 0,
      mobileFirst: true,
      arrows: false,
      variableWidth: true,
      swipe: true,
      infinite: false,
      responsive: [
        {
          breakpoint: 699,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
          },
        },
        {
          breakpoint: 1025,
          settings: {
            touchMove: false,
            slidesToShow: 4,
          },
        },
      ],
    });
  }

  return {
    init,
  };
})();

export default campusSlider;
