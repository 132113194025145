/**
 * Fab shows fab-block content by clicking on fab button
 * @param {*} $
 * @returns {init} Determine and run if fab present
 */

const heroVideo = (() => {
  function init() {
    const $video = $('#myVideo')[0];
    const $btn = $('#myBtn');
    $btn.click(() => {
      if (!$video.paused) {
        $video.pause();
        $btn.addClass('paused');
      } else {
        $video.play();
        $btn.removeClass('paused');
      }
    });
  }

  return {
    init,
  };
})();

export default heroVideo;
