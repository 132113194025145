/**
 * Main-nav displays main navigation block
 * @param {*} $
 * @returns {init} Determine and run if main-nav present
 */

const mainNav = (() => {
  function init() {
    const $navOpener = $('.nav-opener, .burger-opener');
    const $navClose = $('.nav-close');
    const $navBlock = $('.nav-block');
    const $lastFocusedLink = $($('.side-nav a')[$('.side-nav a').length - 1]);

    let timeout = null;

    $navOpener.click(() => {
      $navOpener.attr('aria-expanded', 'true');
      $navBlock.addClass('nav-open').attr('aria-hidden', 'false');

      clearTimeout(timeout);

      timeout = setTimeout(() => {
        $navClose.focus();
      }, 500);
    });

    $lastFocusedLink.on('keydown', e => {
      if (e.keyCode === 9 && e.shiftKey === false) {
        e.preventDefault();
        $navClose.focus();
      }
    });

    $navClose.on('keydown', e => {
      if (e.keyCode === 9 && e.shiftKey === true) {
        e.preventDefault();
        $lastFocusedLink.focus();
      }
    });

    $navClose.click(() => {
      $navOpener.attr('aria-expanded', 'false');
      $navBlock.removeClass('nav-open').attr('aria-hidden', 'true');
    });
  }

  return {
    init,
  };
})();

export default mainNav;
