/**
 * Carousel display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if carousel present
 */

const carousel = (() => {
  // Render carousel
  function setBtnPosition(slick) {
    const currentSlideIndex = slick.currentSlide;
    const $currentSlide = $(slick.$slides[currentSlideIndex + 1]);
    const imgHeight = $currentSlide.find('.carousel__img img').height();

    if ($(window).width() < 700) {
      slick.$nextArrow.css('top', imgHeight);
    } else {
      slick.$nextArrow.css('top', '');
    }
  }

  function renderCarousel() {
    $('.carousel__wrapper').on('init', (event, slick) => {
      let timeOut = null;

      setBtnPosition(slick);

      $(slick.$list)
        .closest('.carousel__wrapper')
        .append('<div class="slides-number"></div>');
      const $slideStatus = $(slick.$list)
        .closest('.carousel__wrapper')
        .find('.slides-number');
      $slideStatus.text(`${slick.currentSlide + 1} of ${slick.$slides.length}`);

      $(window).resize(() => {
        clearTimeout(timeOut);
        timeOut = setTimeout(() => {
          setBtnPosition(slick);
        }, 100);
      });
    });

    $('.carousel__wrapper').on('afterChange', (event, slick, currentSlide) => {
      const $slideStatus = $(slick.$list)
        .closest('.carousel__wrapper')
        .find('.slides-number');
      $slideStatus.text(`${currentSlide + 1} of ${slick.$slides.length}`);

      setBtnPosition(slick);
    });

    $('.carousel__wrapper').slick({
      rows: 0,
      fade: true,
      speed: 200,
    });
  }

  const init = () => {
    if (!$('.carousel').length) {
      return;
    }

    renderCarousel();
  };

  return {
    init,
  };
})();

export default carousel;
