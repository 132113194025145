/**
 * Breadcrumbs-nav shows up hidden dropdowns
 * @param {*} $
 * @returns {init} Determine and run if breadcrumbs-nav present
 */

const breadcrumbsNav = (() => {
  function init() {
    const $navList = $('.breadcrumbs__list');
    const $navBlock = $('.breadcrumbs__holder');
    const $opener = $('.breadcrumbs__opener');
    const PRESSED_SHIFT = 'shift-pressed';
    const HAS_DROP = 'has-drop-down';

    $('.breadcrumbs li').each((i, el) => {
      const $el = $(el);
      if ($el.find('ul').length) {
        $el.addClass(HAS_DROP);
      }
    });

    const $firstDropLink = $navList
      .children('ul')
      .children('li')
      .children('a');
    const $firstSubLinks = $(`.${HAS_DROP} > ul li:first-child a`);

    $opener.click(() => {
      $navBlock.toggleClass('open');
      if ($navBlock.hasClass('open')) {
        $opener.attr('aria-expanded', 'true');
        $navList.attr('aria-hidden', 'false');
      } else {
        $opener.attr('aria-expanded', 'false');
        $navList.attr('aria-hidden', 'true');
      }
    });

    $(window).resize(() => {
      if ($(window).width() > 1024) {
        $navList.removeAttr('aria-hidden');
      } else {
        $navList.attr('aria-hidden', 'true');
      }
    });

    if ($(window).width() > 700) {
      $navList.removeAttr('aria-hidden');
    }

    $(`.${HAS_DROP} ul`).each((i, el) => {
      const $lastFocusedLink = $($(el).find('a')[$(el).find('a').length - 1]);

      $lastFocusedLink.on('blur', e => {
        if (!$navList.hasClass(PRESSED_SHIFT)) {
          $(e.target)
            .closest(`.${HAS_DROP}`)
            .removeClass('open');
        }
      });
    });

    $firstDropLink.on('focus', e => {
      const $this = $(e.target);
      const $parent = $this.closest('li');

      $parent.addClass('open');
      $parent.siblings(`.${HAS_DROP}`).removeClass('open');

      const $firstSublink = $this.siblings('ul').find('li:first-child a');
      const $lastSublink = $this.siblings('ul').find('li:last-child a');

      if (
        $navList.hasClass(PRESSED_SHIFT) &&
        !$firstSublink.hasClass('is-active')
      ) {
        setTimeout(() => {
          $lastSublink.focus();
        }, 50);
      }
    });

    $firstSubLinks.focus(e => {
      $(e.target).addClass('is-active');
    });

    $firstSubLinks.blur(e => {
      setTimeout(() => {
        $(e.target).removeClass('is-active');
      }, 200);
    });

    $navList.on('keydown', e => {
      if (e.shiftKey === true) {
        $navList.addClass(PRESSED_SHIFT);
      }
    });

    $navList.on('keyup', e => {
      if (e.shiftKey === false) {
        $navList.removeClass(PRESSED_SHIFT);
      }
    });
  }

  return {
    init,
  };
})();

export default breadcrumbsNav;
