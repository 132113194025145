/**
 * Scroll-lock prevents page scrolling while main nav and search block are opened
 * @param {*} $
 * @returns {init} Determine and run if scroll-lock present
 */

const scrollLock = (() => {
  function init() {
    const $btnOpen = $('.nav-opener, .burger-opener, .search-opener');
    const $btnClose = $('.nav-close, .search-close');
    const ACTIVE_MENU_CLASS = 'scroll-lock';
    const $body = $('body');
    let topPosition = 0;
    const $window = $(window);

    function _removeBodyScroll() {
      if ($body.hasClass(ACTIVE_MENU_CLASS)) {
        $body.removeClass(ACTIVE_MENU_CLASS);
        $window.scrollTop(topPosition);
        $('.wrapper').css('top', '');
      } else {
        topPosition = $window.scrollTop();
        $('.wrapper').css('top', -topPosition);
        $body.addClass(ACTIVE_MENU_CLASS);
      }
    }

    $btnOpen.click(() => {
      _removeBodyScroll();
    });
    $btnClose.click(() => {
      _removeBodyScroll();
    });
  }

  return {
    init,
  };
})();

export default scrollLock;
