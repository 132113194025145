/**
 * Accordion-simple displays main navigation
 * @param {*} $
 * @returns {init} Determine and run if main-nav present
 */

const $ = require('jquery');

const tableScroll = (() => {
  // Add table scroll buttons to DOM
  const $tableWrap = $('.table-block__holder');
  const $table = $('.table-block table');

  function tableNext() {
    const elWidth = $(this)
      .siblings($table)
      .width();

    $(this)
      .parent($tableWrap)
      .animate(
        {
          scrollLeft: elWidth,
        },
        300,
        'swing',
      );
  }

  function tablePrev() {
    $(this)
      .parent($tableWrap)
      .animate(
        {
          scrollLeft: 0,
        },
        300,
        'swing',
      );
  }

  function tableInit() {
    const wrapWidth = $tableWrap.outerWidth();
    const tableWidth = $tableWrap.find('table').outerWidth();

    if (wrapWidth < tableWidth) {
      $tableWrap.addClass('js-scroll--right');
    } else {
      $tableWrap.removeClass('js-scroll--left js-scroll--right');
    }
  }

  function tableUpdateScroll() {
    const scrollLeft = $tableWrap.scrollLeft();
    const scrollWidth = $tableWrap[0].scrollWidth - $tableWrap[0].clientWidth;

    if (scrollLeft === 0 && scrollWidth > 0) {
      $tableWrap.addClass('js-scroll--right');
      $tableWrap.removeClass('js-scroll--left');
    } else if (scrollLeft === scrollWidth && scrollWidth > 0) {
      $tableWrap.addClass('js-scroll--left');
      $tableWrap.removeClass('js-scroll--right');
    } else {
      $tableWrap.removeClass('js-scroll--left js-scroll--right');
    }
  }

  const init = () => {
    if ($tableWrap.length) {
      $tableWrap.append(
        '<button class="table-block__prev" aria-hidden="true" tabindex="-1">Scroll Right</button>\n' +
          '<button class="table-block__next" aria-hidden="true" tabindex="-1">Scroll Left</button>',
      );

      $('.table-block__next').on('click', tableNext);
      $('.table-block__prev').on('click', tablePrev);

      $(window).on('load resize', tableInit);
      $tableWrap.on('scroll', tableUpdateScroll);
    }
  };

  return {
    init,
  };
})();

export default tableScroll;
